import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";

import { TransitAgencyConfig, configMap } from "./config";
import TransitHeader from "./TransitHeader";
import Clock from "./Clock";
import { gql } from "./__generated__";

const SelectorPage = loadable(() => import("./SelectorPage"));
const SchedulePage = loadable(() => import("./SchedulePage"));

const StopScheduleTablePage = loadable(() => import("./StopScheduleTablePage"));
const RouteScheduleTablePage = loadable(
  () => import("./RouteScheduleTablePage"),
);

const Weather = loadable(() => import("./Weather"));

const GET_SERVER_RELEASE = gql(`
  query ServerRelease {
    release
    agencies {
      name
    }
  }
`);

export default function App() {
  const { data, error } = useQuery(GET_SERVER_RELEASE, {
    fetchPolicy: "cache-and-network",
    pollInterval: 60 * 1000,
    notifyOnNetworkStatusChange: true,
  });
  const [expectedRelease, setExpectedRelease] = useState<string | null>(null);

  if (error) {
    console.error(error);
  }

  if (!expectedRelease && data?.release) {
    console.log(`Setting server release version to ${data.release}`);
    setExpectedRelease(data?.release || "");
  } else if (data?.release && expectedRelease !== data.release) {
    console.error(
      `Currently on release ${expectedRelease}, but found release ${data.release} on the server`,
    );
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  }

  const configKey = expectedRelease?.split("-")[0] || "unknown";
  const config: TransitAgencyConfig = useMemo(() => {
    return configMap[configKey] || {};
  }, [configKey]);
  const agencyName = data?.agencies?.[0]?.name;

  const app = (
    <div className="flex flex-col h-screen">
      {/* Transform needed for the shadow effect */}
      <header className="px-4 h-20 sm:h-20 md:h-24 lg:h-32 xl:h-32 portrait-md:h-32 portrait-lg:h-64 flex flex-none order-1 bg-white justify-between items-center shadow">
        <TransitHeader
          logoUrl={config.logoUrl}
          logoSmUrl={config?.logoSmUrl || null}
          linkUrl={config?.linkUrl}
          className="order-1"
        />
        <div className="order-2 flex divide-x gap-4">
          <Clock className="order-1" />
          <Weather className="order-3" />
        </div>
      </header>
      {/*  MAKE SURE WE DON'T NEED OVERFLOW-HIDDEN HERE OR MAX-H-FULL FOR THE DASHBOARD */}
      <main className="flex flex-auto flex-col order-2 items-stretch bg-brandPrimaryDesat text-base text-black max-h-full min-h-0">
        <Routes>
          <Route
            path="/station/:station"
            element={<SchedulePage config={config} />}
          />
          <Route
            path="/stops/:stops"
            element={<SchedulePage config={config} />}
          />
          <Route
            path="/schedule/route"
            element={<RouteScheduleTablePage config={config} />}
          />
          <Route
            path="/schedule/route/:id/:date"
            element={<RouteScheduleTablePage config={config} />}
          />
          <Route
            path="/schedule/stop"
            element={<StopScheduleTablePage config={config} />}
          />
          <Route
            path="/schedule/stop/:id/:date"
            element={<StopScheduleTablePage config={config} />}
          />
          <Route path="*" element={<SelectorPage config={config} />} />
        </Routes>
        {/* <EmergencyAlert /> */}
      </main>
      {!process.env.REACT_APP_REMOVE_DEBUG_INFO && (
        <footer
          className="px-4 bottom-0 text-right w-full sr-none text-base flex-none font-mono text-gray-700 fixed"
          aria-hidden
        >
          {process.env.REACT_APP_SENTRY_RELEASE?.substr(0, 6) || "HEAD"}
        </footer>
      )}
    </div>
  );

  return (
    <>
      <Helmet
        titleTemplate={`${config.appName} - %s`}
        defaultTitle={config.appName}
      >
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`View the next departures for your ${agencyName} bus or train.`}
        />
        {config.headConfig}
      </Helmet>
      {app}
    </>
  );
}
