// Luxon date formats:
// https://github.com/moment/luxon/blob/master/docs/formatting.md
const timeFormat = "h:mm a";
const fullDateFormat = "MMMM d, y";
const hugeDateFormat = "EEEE',' MMMM d, y";

const configMap: Record<string, TransitAgencyConfig> = {
  grt: require("./config/grt").default,
  york: require("./config/york").default,
};

export { timeFormat, fullDateFormat, hugeDateFormat, configMap };

export type TransitAgencyConfig = {
  headConfig: React.ReactNode;
  logoUrl: string;
  logoSmUrl?: string;
  linkUrl?: string;
  appName: string;
  twitterUsername?: string;
  showPlatformColumn?: boolean;
  maxNextBusPages?: number;
  showConfigFieldsOnSelectionPage: boolean;
};
