import React from "react";
import appleTouchIcon from "../assets/grt/apple-touch-icon.png";
import favicon32 from "../assets/grt/favicon-32x32.png";
import favicon16 from "../assets/grt/favicon-32x32.png";
import safariPinnedTab from "../assets/grt/safari-pinned-tab.svg";

import logo from "../assets/grt/logo.png";
import { TransitAgencyConfig } from "../config";

export const headConfig = (
  <>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
    <link rel="manifest" href={"/grt-manifest.json"} />
    <link rel="mask-icon" href={safariPinnedTab} color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#ffc40d" />
    {/* <meta name="theme-color" content="#ffffff"></meta> */}
  </>
);

const config: TransitAgencyConfig = {
  headConfig,
  appName: "GRT NextRide",
  logoUrl: logo,
  linkUrl: "https://grt.ca/",
  twitterUsername: "GRT_ROW",
  showConfigFieldsOnSelectionPage: false,
};
export default config;
